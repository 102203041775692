const iconList = [ // 地图管理  图标选择 下拉选
    'icon-zuobiao',
    'icon-gongchang',
    'icon-tubiaolunkuo-',
    'icon-xianshiqi',
    'icon-shuibeng'
]

const iconfontList = [ // 阿里云图标
    'icon-in-storage',
    'icon-out-storage',
    'icon-right-arrow',
    'icon-cangchuwuliu',
    'icon-cangchuwuliu-b',
    'icon-kucun',
    'icon-kucun-b',
    'icon-jianhuo',
    'icon-jianhuo-b'
]
export {iconList, iconfontList}
