const iconList = [ // 地图管理  图标选择 下拉选
    'icon-zuobiao',
    'icon-gongchang',
    'icon-tubiaolunkuo-',
    'icon-xianshiqi',
    'icon-shuibeng'
]

const aliIconList = [ // 阿里云图标
    'icon-in-storage',
    'icon-out-storage',
    'icon-right-arrow',
    'icon-cangchuwuliu',
    'icon-cangchuwuliu-b',
    'icon-kucun',
    'icon-kucun-b',
    'icon-jianhuo',
    'icon-jianhuo-b',
    'icon-yiku',
    'icon-tongzhidan',
]
const standLocationStatus = [
    {
        title: '空货位',
        status: 'EMPTY',
        color: '#196cc0'
    },
    {
        title: '选中货位',
        status: 'SELECT',
        color: '#e64980'
    },
    {
        title: '有货物',
        status: 'NONEMPTY',
        color: '#20c997'
    },
    {
        title: '人工锁定',
        status: 'LOCKED',
        color: '#c92a2a'
    },
    {
        title: '锁定-出库中',
        status: 'EX_WAREHOUSING',
        color: '#5f3dc4'
    },
    {
        title: '锁定-入库中',
        status: 'IN_WAREHOUSING',
        color: '#3bc9db'
    },
    {
        title: '移库中',
        status: 'MOVING',
        color: '#ff922b'
    },
    {
        title: '同产品批次',
        color: '#fd7504',
        status: 'SAME',
        type: '移库'
    },
]
const shelvesLocationStatus = [
    {
        title: '无货',
        status: 'EMPTY',
        color: '#196cc0'
    },
    {
        title: '选中货位',
        status: 'SELECT',
        color: '#e64980'
    },
    {
        title: '部分有货',
        status: 'PART',
        color: '#3bc9db'
    },
    {
        title: '满货',
        status: 'FULL',
        color: '#20c997'
    },

]
export { iconList, aliIconList, standLocationStatus, shelvesLocationStatus }
