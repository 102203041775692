// 此文件是通过脚本生成的，直接编辑无效！！！

// 不需要导航框架的页面路径
export const noFrames = [
    '/login',
];

// 不需要登录就可以访问的页面路径
export const noAuths = [
    '/login',
];

// 需要keep alive 页面
export const keepAlives = [
];

// 页面路由配置
export default [
    {
        path: '/business/alert/list',
        component: () => import('D:/Workspace/haiyue_project/haiyue_wxapp_web/src/pages/business/alert/alert_manage/index.jsx'),
    },
    {
        path: '/business/alert_push_log/list',
        component: () => import('D:/Workspace/haiyue_project/haiyue_wxapp_web/src/pages/business/alert/alert_push_log/index.jsx'),
    },
    {
        path: '/business/alert_push/list',
        component: () => import('D:/Workspace/haiyue_project/haiyue_wxapp_web/src/pages/business/alert/alert_push/index.jsx'),
    },
    {
        path: '/business/alert_record/list',
        component: () => import('D:/Workspace/haiyue_project/haiyue_wxapp_web/src/pages/business/alert/alert_record/index.jsx'),
    },
    {
        path: '/business/area/list',
        component: () => import('D:/Workspace/haiyue_project/haiyue_wxapp_web/src/pages/business/device_manage/area/index.jsx'),
    },
    {
        path: '/business/device_model/list',
        component: () => import('D:/Workspace/haiyue_project/haiyue_wxapp_web/src/pages/business/device_manage/device_model/index.jsx'),
    },
    {
        path: '/business/device/list',
        component: () => import('D:/Workspace/haiyue_project/haiyue_wxapp_web/src/pages/business/device_manage/device/index.jsx'),
    },
    {
        path: '/business/inspection_template/list',
        component: () => import('D:/Workspace/haiyue_project/haiyue_wxapp_web/src/pages/business/inspection_manage/inspection_template/index.jsx'),
    },
    {
        path: '/business/inspection/list',
        component: () => import('D:/Workspace/haiyue_project/haiyue_wxapp_web/src/pages/business/inspection_manage/inspection/index.jsx'),
    },
    {
        path: '/business/project/my_list',
        component: () => import('D:/Workspace/haiyue_project/haiyue_wxapp_web/src/pages/business/project/my_project/index.jsx'),
    },
    {
        path: '/business/project/list',
        component: () => import('D:/Workspace/haiyue_project/haiyue_wxapp_web/src/pages/business/project/project_manage/index.jsx'),
    },
    {
        path: '/business/tag/list',
        component: () => import('D:/Workspace/haiyue_project/haiyue_wxapp_web/src/pages/business/project/tag_manage/index.jsx'),
    },
    {
        path: '/',
        component: () => import('D:/Workspace/haiyue_project/haiyue_wxapp_web/src/pages/home/index.jsx'),
    },
    {
        path: '/login',
        component: () => import('D:/Workspace/haiyue_project/haiyue_wxapp_web/src/pages/login/index.jsx'),
    },
    {
        path: '/report/iframe/dynamic_report/:name/:icon/:iframeUrl',
        component: () => import('D:/Workspace/haiyue_project/haiyue_wxapp_web/src/pages/report_iframe/dynamic_report.jsx'),
    },
    {
        path: '/system/access_log/list',
        component: () => import('D:/Workspace/haiyue_project/haiyue_wxapp_web/src/pages/system_manage/access_log/index.jsx'),
    },
    {
        path: '/system/department/list',
        component: () => import('D:/Workspace/haiyue_project/haiyue_wxapp_web/src/pages/system_manage/department/index.jsx'),
    },
    {
        path: '/system/dictionary/list',
        component: () => import('D:/Workspace/haiyue_project/haiyue_wxapp_web/src/pages/system_manage/dictionary/index.jsx'),
    },
    {
        path: '/system/permission/list',
        component: () => import('D:/Workspace/haiyue_project/haiyue_wxapp_web/src/pages/system_manage/menu_manage/index.jsx'),
    },
    {
        path: '/system/role/list',
        component: () => import('D:/Workspace/haiyue_project/haiyue_wxapp_web/src/pages/system_manage/role/index.jsx'),
    },
    {
        path: '/system/tenant/list',
        component: () => import('D:/Workspace/haiyue_project/haiyue_wxapp_web/src/pages/system_manage/tenant/index.jsx'),
    },
    {
        path: '/system/user/list',
        component: () => import('D:/Workspace/haiyue_project/haiyue_wxapp_web/src/pages/system_manage/user/index.jsx'),
    },
    {
        path: '/test_2/:value',
        component: () => import('D:/Workspace/haiyue_project/haiyue_wxapp_web/src/pages/test_page/test_1.jsx'),
    },
];
