import axios from 'axios';
import Cookies from 'js-cookie';
import {message} from "antd";
import NotificationToLogin from './notification_to_login'
import {toLogin} from "../commons";
let api = {
    baseURL: window.baseURL ? `${window.baseURL}/api` : `http://${window.location.hostname}/api`,
    baseUploadURL: window.baseURL ? `${window.baseURL}:${window.port}/api` : `http://${window.location.hostname}:9003/api`,
    // baseURL: 'http://192.168.0.106:9003/api',
    // baseUploadURL:'http://192.168.0.106:9003/api',
};
axios.defaults.timeout = 240000;
axios.defaults.baseURL = api.baseURL;
axios.interceptors.request.use(
    function (config) {
        // 在发送请求之前做些什么
        config.headers.common['Authorization'] =
            Cookies.get('plant01_token_type') +
            ' ' +
            Cookies.get('plant01_token');
        // Cookies.get('tokenTest');
        return config;
    },
    function (error) {
        // 对请求错误做些什么
        return Promise.reject(error);
    }
);

/**
 * get请求
 * @param url
 * @param params
 * @returns {Promise}
 */
export function fetch(url, params = {}) {
    axios.defaults.baseURL = api.baseURL
    return new Promise((resolve, reject) => {
        axios.get(url, {params: params})
            .then(res => {
                let resolveData = {
                    ...res.data,
                    status: res.data.success ? 'success' : 'error'
                }
                if (!res.data.success) {
                    if (res.data.code === 400) {
                        NotificationToLogin()
                    }
                    // else if ( res.data.code === 400 ) {
                    //     toLogin()
                    // }
                    else {
                        message.error(res.data.msg)
                    }
                } else {

                }
                resolve(resolveData);
            })
            .catch(err => {
                reject(err);
            }).finally((e) => resolve(e))
    });
}

/**
 * post请求
 * @param url
 * @param params
 * @isUpload 是不是上传（调用服务器地址）
 * @returns {Promise}
 */
export function post(url, params = {}, successTip) {
    axios.defaults.baseURL = api.baseURL
    return new Promise((resolve, reject) => {
        axios
            .post(url, params)
            .then(res => {
                let resolveData = {
                    ...res.data,
                    status: res.data.success ? 'success' : 'error'
                }
                if (res.data.success) {
                    if (successTip) {
                        message.success(successTip)
                    }
                } else {
                    if (res.data.code === 400) {
                        NotificationToLogin()
                    }
                    // else if ( res.data.code === 400 ) {
                    //     toLogin()
                    // }
                    else {
                        message.error(res.data.msg)
                    }
                }
                resolve(resolveData);
            })
            .catch(err => {
                reject(err);
            });
    });
}

/**
 * get请求
 * @param url
 * @param params
 * @returns {Promise}
 */
export function fetchSVG(url, params = {}) {
    axios.defaults.baseURL = api.baseURL
    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                params: params
            })
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err);
            });
    });
}


/**
 * postWcs
 * @param url
 * @param params
 * @returns {Promise}
 */
export function postWcs(url, params = {}, successTip) {
    axios.defaults.baseURL = api.baseURL
    return new Promise((resolve, reject) => {
        axios
            .post(url, params)
            .then(res => {
                if (res.data.returnStatus === 0) {
                    if (successTip) {
                        message.success(successTip)
                    }
                } else {
                    message.error(res.data.returnInfo)
                }
                resolve(res);
            })
            .catch(err => {
                reject(err);
            });
    });
}

export function getBlob(url, params = {}) {
    axios.defaults.baseURL = api.baseURL
    return new Promise((resolve, reject) => {
        axios({
            method: 'GET',
            url: url,
            params: params,
            // responseType: 'blob',
            responseType: 'arraybuffer',
        })
            .then(response => {
                resolve(response.data);
            })
            .catch(err => {
                reject(err);
            });
    });
}

export function postBlob(url, params = {}) {
    axios.defaults.baseURL = api.baseURL
    console.log(axios.defaults.baseURL)
    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: url,
            params: params,
            responseType: 'blob',
            // responseType: 'arraybuffer',
        })
            .then(response => {
                resolve(response.data);
            })
            .catch(err => {
                reject(err);
            });
    });
}
export function postTest(url, params = {}, successTip) {
    axios.defaults.baseURL = api.baseURL
    return new Promise((resolve, reject) => {
        axios
            .post(url, params,{responseType: 'blob'})
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err);
            });
    });
}

/**
 * 上传附件
 * @param url
 * @param data
 * @returns {Promise}
 */
export function postUpload(url, data = {}) {
    axios.defaults.baseURL = api.baseUploadURL
    return new Promise((resolve, reject) => {
        axios.post(url, data)
            .then(response => {
                resolve(response.data)
            })
            .catch(err => {
                reject(err)
            })
    })
}

export function getUpload(url, params = {}) {
    axios.defaults.baseURL = api.baseUploadURL
    return new Promise((resolve, reject) => {
        axios({
            method: 'GET',
            url: url,
            params: params,
            //responseType: 'blob',
            responseType: 'arraybuffer',
        })
            .then(response => {
                resolve(response.data);
            })
            .catch(err => {
                reject(err);
            });
    });
}

export default api;
