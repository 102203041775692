import React, {Component, Fragment} from 'react';
import { Popover } from 'antd'
import {SketchPicker} from 'react-color'
import './index.less'
export default class IconPicker extends Component {
    state = {
        visible: false,
        color: '',
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        const {value} = nextProps;
        return {color: value};
    }

    handleOk = (color) => {
        const {onChange} = this.props;
        if (onChange) onChange(color);

        this.setState({visible: false});
    };
  changeColor =(e)=> {
    let newList = []
    newList.push(e.rgb.r)
    newList.push(e.rgb.g)
    newList.push(e.rgb.b)
    newList.push(e.rgb.a)
    const {onChange} = this.props;
    let color = 'rgba(' + newList.toString() + ')'
    if (onChange) onChange(color);
  }
    render() {
        const {
          color,
        } = this.state;
        return (
            <Fragment>
              <Popover trigger="click" content={
              <SketchPicker
              color={color || ''}
              onChangeComplete={this.changeColor}
              />
              }>
              <div className='form_select_color_module' style={{background: color}}/>
              </Popover>
            </Fragment>
        );
    }
}
