import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Icon} from 'src/library/components';
import {Menu} from 'antd';
import {renderNode} from 'src/library/utils/tree-utils';
import './style.less';
import SideMenuPopover from '../side-menu-popover/index'
import {filledIconList} from '../../../menusIcons'
import Logo from "./logo/index";
import {aliIconList} from '../../../globalData'
import {createFromIconfontCN} from '@ant-design/icons';
const IconFont = createFromIconfontCN({
    scriptUrl: '/icon_js/iconfont.js',
});
export default class SideMenu extends Component {
    static propTypes = {
        dataSource: PropTypes.array,    // 菜单数据
        theme: PropTypes.string,        // 主题
        collapsed: PropTypes.bool,      // 是否收起
        openKeys: PropTypes.array,      // 打开菜单keys
        selectedKeys: PropTypes.array,  // 选中菜单keys
        onOpenChange: PropTypes.func,   // 菜单打开关闭时触发
    };

    static defaultProps = {
        dataSource: [],
        theme: 'dark',
        collapsed: false,
        openKeys: [],
        selectedKeys: [],
        onOpenChange: () => true,
    };

    handleOpenChange = (openKeys) => {
        this.props.onOpenChange(openKeys);
    };

    renderMenus() {
        const {dataSource} = this.props;
        if (dataSource && dataSource.length) {
            return renderNode(dataSource, (item, children) => {
                const {
                    key,
                    text,
                    icon,
                    target,
                    url,
                } = item;

                let title = <span>{text}</span>;
                if (icon) title =
                    <div className='title-div'>
                        <div className='icon-div'>
                            {
                                aliIconList.includes(icon) ?
                                    <IconFont type={icon} style={{fontSize: 27, marginBottom: 10}}/> :
                                    <Icon className='icon' type={icon}
                                          theme={filledIconList.includes(icon) ? 'filled' : ''}/>
                            }
                        </div>
                        <div className='text-div'>{text}</div>
                    </div>;
                return (
                    <Menu.Item key={key}>
                        {target ? (
                                <a href={url} target={target}>
                                    {title}
                                </a>
                            ) :
                            (
                                <SideMenuPopover
                                    dataSource={dataSource}
                                    menu={item}
                                >
                                    {title}
                                </SideMenuPopover>
                            )
                        }
                    </Menu.Item>
                );
            });
        }
        return null;
    }

    render() {
        let {theme, collapsed, openKeys} = this.props;
        const menuProps = collapsed ? {} : {
            openKeys,
        };

        return (
            <div styleName="side-menu">
                <Menu
                    {...menuProps}
                    mode="inline"
                    theme={theme}
                    inlineCollapsed={collapsed}
                    onOpenChange={this.handleOpenChange}
                >
                    {this.renderMenus()}
                </Menu>
                <div className='logo_bottom_div'>
                    <Logo
                        title="React Admin"
                    />
                </div>
            </div>
        );
    }
}
