import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {MenuFoldOutlined, MenuUnfoldOutlined} from '@ant-design/icons';
import Link from '../page-link';
import Logo from './logo';
import HeaderUser from './header-user';
import HeaderMenu from './header-menu';
import HeaderFullScreen from './header-full-screen';
import {connect} from 'src/models/index';
import {PAGE_FRAME_LAYOUT} from 'src/models/settings';
import Breadcrumb from '../breadcrumb';
import './style.less';
import Cookies from 'js-cookie'
import {fetch} from "../../utils/axios_utils";
import config from "../../commons/config-hoc";
import HeaderStartMenu from './header_start_menu'
import {ROUTE_BASE_NAME} from "../../router/AppRouter";
@config({ajax: true, router: true,})
@connect(state => {
    const {menus} = state.menu;
    const {show: showSide, width, collapsed, collapsedWidth, dragging, collectRoute} = state.side;
    const {breadcrumbs} = state.page;
    const {pageFrameLayout} = state.settings;
    return {
        menus,
        showSide,
        sideWidth: width,
        sideCollapsed: collapsed,
        sideCollapsedWidth: collapsedWidth,
        sideDragging: dragging,
        breadcrumbs,
        collectRoute,
        layout: pageFrameLayout,
    };
})
export default class Header extends Component {
    state = {
        projects: [],
    }
    timerID = null
    static propTypes = {
        layout: PropTypes.string,
        theme: PropTypes.string,
    };

    static defaultProps = {
        layout: PAGE_FRAME_LAYOUT.SIDE_MENU,    // top-side-menu top-menu side-menu
        theme: 'default',                       // default dark
    };
    componentDidMount() {
        this.getDataById()
    }
    componentWillUnmount() {

    }
    logout() {
        Cookies.set('plant01_user', '', {expires: 1});
        Cookies.set('userId', '', {expires: 1});
        Cookies.set('plant01_token', '', {expires: 1});
        Cookies.set('plant01_token_type', '', {expires: 1});
        const loginPath = '/login';
        window.location.href = `${ROUTE_BASE_NAME}${loginPath}`;
        // this.props.ajax.post('/mock/logout').then(toLogin);
    }
    getDataById() {
        let id = Cookies.get('userId')
        fetch('/system/user/get', {id})
            .then(res => {
                if (res.status === 'success') {
                    this.setState({projects: res.data.projects})
                }
            })
            .catch(err => {
                console.log(err);
            }).finally(() => this.setState({loading: false}));
    }

    handleToggle = () => {
        const {sideCollapsed} = this.props;
        this.props.action.side.setCollapsed(!sideCollapsed);
    };

    renderToggle = (showToggle, sideCollapsed, theme) => {
        if (!showToggle) return null;

        const props = {
            onClick: this.handleToggle,
            style: theme === 'dark' ? {color: '#fff', backgroundColor: '#222'} : null,
        };

        return sideCollapsed ? <MenuUnfoldOutlined {...props} styleName="trigger"/> :
            <MenuFoldOutlined {...props} styleName="trigger"/>;
    };

    jumpNewHome = () => {
        window.location.href = '/home_new'
    }

    render() {
        let {
            layout,
            menus,          // 所有的菜单数据
            topMenu,        // 当前页面选中菜单的顶级菜单
            sideCollapsed,
            sideCollapsedWidth,
            sideWidth,
            sideDragging,
            breadcrumbs,
            children,
        } = this.props;
        sideWidth = sideCollapsed ? sideCollapsedWidth : sideWidth;
        const isTopSideMenu = layout === PAGE_FRAME_LAYOUT.TOP_SIDE_MENU;
        const isTopMenu = layout === PAGE_FRAME_LAYOUT.TOP_MENU;
        const isSideMenu = layout === PAGE_FRAME_LAYOUT.SIDE_MENU;
        const showMenu = isTopSideMenu || isTopMenu;
        let topMenus = menus;
        if (isTopSideMenu) {
            topMenus = menus && menus.map(item => ({key: item.key, text: item.text, path: item.path, icon: item.icon}));
        }
        if (isTopMenu) {
            topMenus = menus;
        }
        let transitionDuration = sideDragging ? '0ms' : '300ms';

        const theme = this.props.theme || ((isTopSideMenu || isSideMenu) ? 'default' : 'dark');
        let {collectRoute} = this.props
        return (
            <div id="header" styleName="header" data-theme={theme}>
                <div styleName="logo-container" id="logo-container"
                     style={{flex: `0 0 ${sideWidth}px`, transitionDuration}}>
                    <Link to="/">
                        <Logo
                            min={sideCollapsed}
                            title="React Admin"
                        />
                    </Link>
                </div>
                {children ? (
                    <div styleName="center">{children}</div>
                ) : (
                    <div styleName="center">
                        {showMenu ? (
                            <HeaderMenu
                                theme={theme}
                                dataSource={topMenus}
                                selectedKeys={[topMenu && topMenu.key]}
                            />
                        ) : null}
                        {isSideMenu ? <div style={{marginLeft: 16}}>
                            <Breadcrumb theme={theme} dataSource={breadcrumbs}/>
                        </div> : null}
                    </div>
                )}
                <div style={{color: '#fff'}}>
                    {
                        collectRoute.length !== 0 ? ' |' : null
                    }
                </div>
                <div styleName='start_menu'>
                    <HeaderStartMenu/>
                </div>
                <div styleName="right">
                    <HeaderFullScreen styleName="action"/>                     {/*全屏    */}
                    <HeaderUser styleName="action" theme={theme}/>
                </div>
            </div>
        );
    }
}
