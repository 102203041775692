import React, {Component} from 'react';
import PropTypes from 'prop-types';
import logo from './logo.png';
import './style.less';
import TestLogo from 'src/pages/login/haiyue_logo.png'
export default class Logo extends Component {
    static propTypes = {
        min: PropTypes.bool,
    };
    static defaultProps = {
        logo: logo,
        title: 'React Web',
        min: false,
    };
    componentWillMount() {

    }
  render() {
    // let logoUrl = api.baseUploadURL + '/system/project/get_logo?id=' + Cookies.get('project_id')
        return (
            <div styleName="logo">
                <img src={TestLogo} alt="logo" style={{ height: 37, marginTop: 6 }}/>
            </div>
        );
    }
}
