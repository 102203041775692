import React, {Component} from 'react';
import {Popover, Row, Card} from "antd";
import {Icon} from 'src/library/components';
import config from '@/commons/config-hoc';
import {aliIconList} from '../../../globalData'
import { StarOutlined, StarFilled } from '@ant-design/icons'
import './style.less'
import {post} from "../../../utils/axios_utils";
import {connect} from "../../../models";
@config({
    router: true,
})
@connect(state => {
    const {collectRoute} = state.side;
    return {
        collectRoute,
    };
})
export default class Path extends Component {
    state = {
    };
    componentDidMount() {
        // console.log(this.props.collectRoute)
    }

    jumpPage = (item) => {
        if (item.openMode === 'IFRAME') {
            this.props.history.push({ pathname: `/report/iframe/dynamic_report/${item.text}/${item.icon}/${item.iframeUrl}`})
        } else {
            this.props.history.push(item.path)
        }
    }
    startClick =(child, e )=> {
        e.preventDefault();
        e.stopPropagation();
        let collectRoute  = [...this.props.collectRoute]
        let { setCollectRoute } = this.props.action.side
        let currentIndex = collectRoute.findIndex(item => item.id === child.key)
        if (currentIndex === -1) {
            collectRoute.push({
                id: child.key,
                route: child.path,
                icon: child.icon,
                name: child.text
            })
        } else {
            collectRoute.splice(currentIndex, 1)
        }
        setCollectRoute(collectRoute)
        this.userRouteCollection(child.key)
    }
    /**
     * 取消或收藏
     */
    userRouteCollection =(id)=> {
        let params = {
            permission: {
                id: id
            }
        }
        post('/system/user_route_collection/collection', params)
            .then(res => {
                if (res.success) {

                }
            })
    }
    render() {
        const {menu, children, collectRoute} = this.props
        return (
            <div>
                <Popover
                    overlayClassName='side_menu_popover_div'
                    content={
                        <div className='popover_content'>
                            <Row className='card_div' style={{maxHeight: window.innerHeight - 20}}>
                                {
                                    menu.children.filter(item=> item.isMenu).map(children => {
                                            return <Card title={children.text}
                                                         bordered={false}
                                                         style={{width: '100%', background: '#23333A', color: '#fff'}}
                                                         headStyle={{
                                                             color: '#fff',
                                                             minHeight: 25,
                                                             borderBottom: '1px solid #23333A',
                                                             padding: '0px 6px',
                                                             margin: '5px 0px'
                                                         }}
                                                         bodyStyle={{padding: 0}}
                                            >
                                                <div style={{
                                                    display: 'flex', flexWrap: 'wrap' +
                                                        ''
                                                }}>
                                                    {
                                                        children.children.filter(item=> item.isMenu).map(child => {
                                                            return <div key={child.id} className='icon_card'>
                                                                <div className='icon_card_div'
                                                                     onClick={this.jumpPage.bind(this,  child)}>
                                                                    {
                                                                        collectRoute.find(menu => menu.route === child.path) ?
                                                                            <div className='start_div' onClick={this.startClick.bind(this,child)}>
                                                                                <StarFilled style={{ fontSize: 17, color: '#e8cb75' }}/>
                                                                            </div> :
                                                                            <div className='not_start_div' onClick={this.startClick.bind(this,child)}>
                                                                                <StarOutlined style={{ fontSize: 17 }}/>
                                                                            </div>
                                                                    }

                                                                    {
                                                                        aliIconList.includes(child.icon) ?
                                                                            <i className={'iconfont ' + child.icon}
                                                                               style={{
                                                                                   fontSize: 18,
                                                                                   marginBottom: 3
                                                                               }}
                                                                            >
                                                                            </i>
                                                                            :
                                                                            <Icon type={child.icon}
                                                                                  style={{fontSize: 18, marginBottom: 10}}/>
                                                                    }

                                                                    <div style={{fontSize: 12}}>{child.text}</div>
                                                                </div>
                                                            </div>
                                                        })
                                                    }
                                                </div>
                                            </Card>
                                        }
                                    )
                                }
                            </Row>
                        </div>
                    }
                    key={menu.icon}
                    placement="right"
                    mouseEnterDelay={0.3}
                    // trigger="click"
                    // trigger="focus"
                    // visible={this.state.visible}
                    // onVisibleChange={this.handleVisibleChange}
                >
                    <div className='popover_content_div' tabIndex="0">
                        {children}
                    </div>
                </Popover>
            </div>
        );
    }
}
