import React, {Component} from 'react';
import { Menu, Tooltip } from 'antd'
import {Icon} from 'src/library/components';
import {iconfontList} from "../../../iconfontList";
import config from '@/commons/config-hoc';
import {connect} from "../../../models";
import {post} from "../../../utils/axios_utils";
@config({
    router: true,
})
@connect(state => {
    const {collectRoute} = state.side;
    return {
        collectRoute,
    };
})
export default class SystemContactUnit extends Component {
    state = {

    };

    componentDidMount() {
    }
    handleClick =(e)=> {
        this.props.history.push(e.key)
    }
    cancelCollection(child, e ) {
        e.preventDefault();
        e.stopPropagation();
        let collectRoute  = [...this.props.collectRoute]
        let { setCollectRoute } = this.props.action.side
        let currentIndex = collectRoute.findIndex(item => item.id === child.id)
        if (currentIndex === -1) {
            collectRoute.push({
                id: child.key,
                route: child.path,
                icon: child.icon,
                name: child.text
            })
        } else {
            collectRoute.splice(currentIndex, 1)
        }
        setCollectRoute(collectRoute)
        this.userRouteCollection(child.id)
    }
    /**
     * 取消或收藏
     */
    userRouteCollection =(id)=> {
        let params = {
            permission: {
                id: id
            }
        }
        post('/system/user_route_collection/collection', params)
            .then(res => {
                if (res.status === 'success') {

                }
            })
    }
    render() {
        let { collectRoute } = this.props
        return (
            <Menu onClick={this.handleClick} mode="horizontal" theme={'dark'} popupClassName='start_menu_popup'
                  selectable={false}
            >
                {
                    collectRoute.map(item =>  <Menu.Item key={item.route} >
                        <Tooltip title={ <div>
                            <a onClick={this.cancelCollection.bind(this, item)} style={{ color: '#fff' }}>取消收藏</a>
                        </div>}>
                            {
                                iconfontList.includes(item.icon) ?
                                    <i className={'iconfont ' + item.icon}
                                       style={{ marginRight: 2 }}
                                    >
                                    </i>
                                    :
                                    <Icon type={item.icon} style={{ marginRight: 2 }}/>
                            }{item.name}
                        </Tooltip>
                    </Menu.Item>)
                }
            </Menu>
        );
    }
}
