import {Button, notification} from "antd";
import {toLogin} from "../commons";
import {ExclamationCircleFilled} from "@ant-design/icons";
import React from "react";

let key = 'token'
const btn = (
    <Button type="primary" size="small" onClick={() => {
        notification.close(key)
        toLogin()
    }}>
        重新登录
    </Button>
);
let icon = <ExclamationCircleFilled style={{ color: '#faad14' }}/>


export default function NotificationToLogin () {
    notification.open({
        key,
        btn,
        icon,
        message: 'TOKEN过期',
        description: '请重新登录！',
    });
}
